import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { EMPTY, Subscription, catchError, concatMap, iif, map, of, tap } from 'rxjs';
import { SocketioService } from '../socketio/socketio.service';
import Peer from 'peerjs';
import { WMLConstructorDecorator, WMLCustomComponent, WMLDeepPartial, WMLUIProperty } from '@windmillcode/wml-components-base';
import { ListJobsEntity } from '../jobs/listJobs';
import { JobsService } from '../jobs/jobs.service';
import { CSSVARS, isMobileIOSDevice, transformObjectKeys } from '@core/utility/common-utils';
import { makeLowerCase, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from '@core/utility/string-utils';
import { SocketioClientResponseModel } from '../socketio/models/socketio-client-resp-model';
import { deepCopyInclude } from '@core/utility/object-utils';
import { arrayBufferToBase64, base64ToUint8Array, readFileContent } from '@core/utility/file-utils';
import { HttpUtils } from '@core/utility/http-utils';
import { ConfirmDialogZeroComponent, ConfirmDialogZeroProps } from '@shared/components/confirm-dialog-zero/confirm-dialog-zero.component';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { respondToFileTransferRequestSuccess, RespondToFileTransferRequestUIRequestBody } from '../jobs/respondToFileTransferRequest';
import { BaseService } from '@core/base/base.service';
import { AccountsService } from '../accounts/accounts.service';
import { requestFileTransferSuccess } from '../jobs/requestFileTransfer';
import { ENV } from '@env/environment';
import { CHUNK_SIZE_RATIO, NEED_MORE_CREDITS, RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS } from '@core/utility/constants';
import { SpecificService } from '@core/specific/specific.service';
import { StoreService } from '../store/store.service';
import { UpdateJobsUIRequestBody } from '../jobs/updateJobs';
import { UseCreditsUIRequestBody } from '../store/useCredits';
import {captureMessage} from "@sentry/angular-ivy";
import { getProgressMetrics } from '@shared/components/transfer-local-files-job-zero/transfer-local-files-job-zero.component';

@Injectable({
  providedIn: 'root'
})
export class WebRTCService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService,
    public socketioService:SocketioService,
    public jobsService:JobsService,
    public baseService:BaseService,
    public accountsService:AccountsService,
    public specificService:SpecificService,
    public storeService:StoreService
  ) {
    this.reInit()
  }

  peer:Peer

  reInit= ()=>{
    this.peer = new Peer(ENV.peerjs)
  }

  connect = (peerId)=>{
    let conn = this.peer.connect(peerId);
    if( !conn){
      this.peer = new Peer(ENV.peerjs)
      return this.connect(peerId)
    }
    return conn
  }

  determineJobResults(targetJob: ListJobsEntity) {
    let failedAmount = targetJob.details.transferLocalFilesDetails.files
    .reduce((acc, x) => {
      if (x.status === "FAILED") {
        acc += 1;
      }
      return acc;
    }, 0);
    let status
    if (failedAmount === 0) {
      status = "SUCCESS"
    }
    else{
      this.baseService.createWMLNote("TransferLocalFilesJobZero.WMLNotifyOne.errorOnSendingFiles",WMLNotifyOneBarType.Error)
      if (failedAmount !== targetJob.details.transferLocalFilesDetails.files.length) {
        status= "ISSUES"
      }
      else if (failedAmount === targetJob.details.transferLocalFilesDetails.files.length) {
        status = "FAILED"
      }
    }
    this.updateJob({ status, jobId: targetJob.id,details:transformObjectKeys(targetJob.details,transformFromCamelCaseToSnakeCase) });
    return {successAmount:targetJob.details.transferLocalFilesDetails.files.length-failedAmount,status}
  }

  updateJob =(input:Partial<{status:ListJobsEntity["status"],jobId:string,details:any,forceDataSourceUpdate:boolean,preventScrollReset:boolean}>)=>{
    let {status,jobId,details,forceDataSourceUpdate,preventScrollReset} = input
    this.socketioService.fileTransferEvent.next(
      new SocketioClientResponseModel({
        data: {
          taskId: null,
          result: {
            prevent_scroll_reset:preventScrollReset,
            after_job_info: [
              {
                job_id: jobId,
                status,
                details,
                force_datasource_update:forceDataSourceUpdate
              }
            ]
          }
        }
      })
    )
  }

  listenForReceiveEventSub:Subscription
  listenForReceiveEvent = (input?:{skipWakeLock})=>{
    this.listenForReceiveEventSub?.unsubscribe()
    let {skipWakeLock} = input ??{}
    return iif(
      ()=>skipWakeLock,
      of(null),
      this.specificService.getWakeLock()
    )
    .pipe(
      tap((wakeLock)=>{
        this.peer.on("connection", (conn) => {

          let registeredJob =false
          let completedJob= false
          let sendPacket = (input:Partial<{offset,data,status:WebRTCDataPacket["fileTransfer"]["status"]}>)=>{
            let {offset,data,status} =input
            setTimeout(() => {
              conn.send(new WebRTCDataPacket({
                type:"FILE_TRANSFER",
                fileTransfer:{
                  ...(data?.fileTransfer ??{}),
                  ...(offset != null ? {
                    chunk:{
                      offset
                    }
                  }:{}),
                  status,
                  recipientDelayTime:RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS
                }
              }))
            }, RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS);

          }

          let getTargetFile = (targetJob:ListJobsEntity , fileTransfer,casing:"snake"|"camel" = "snake")=> {

            let details = "transfer_local_files_details"
            if(casing=="camel"){
              details = transformFromSnakeCaseToCamelCase(details)
            }
            // @ts-ignore
            return targetJob.details[details].files.find(
              (file) => file['name'] == fileTransfer['name'] && file['type'] == fileTransfer['type'],
            );
          };

          conn.on("error",(err)=>{
            this.baseService.createWMLNote("TransferLocalFilesJobZero.WMLNotifyOne.errorOnSendingFiles",WMLNotifyOneBarType.Error)
            wakeLock?.release().then(()=>{})
          })

          conn.on("data", async (data:WebRTCDataPacket) => {
            console.log(data.fileTransfer)
            if(data.type ==="FILE_TRANSFER"){
              // console.log(data.fileTransfer)
              let targetJob:ListJobsEntity
              let targetJobIndex:number
              let targetFile:ListJobsEntity["details"]["transferLocalFilesDetails"]["files"][number]
              let {fileTransfer} = data
              try {

                targetJobIndex = this.jobsService.listJobsDataSource.currentSource.data.findIndex((job)=>{
                  return job.id  === fileTransfer.jobId
                })
                targetJob = this.jobsService.listJobsDataSource.currentSource.data[targetJobIndex]
                if(fileTransfer.status === "FILE_START"){
                  let fileStream = streamSaver.createWriteStream(data.fileTransfer.name,{
                    size:data.fileTransfer.size
                  })
                  let writer = fileStream.getWriter()
                  if(!targetJob){
                    let estimatedTimeInMs = getProgressMetrics(fileTransfer["totalJobSize"],
                      Math.ceil(conn.messageSize * CHUNK_SIZE_RATIO),
                          RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS)['estimatedTimeInMs'];
                          fileTransfer["estimatedTimeInMs"] = estimatedTimeInMs;
                    targetJob =  new ListJobsEntity({
                      id:data.fileTransfer.jobId,
                      status:"IN_PROGRESS",
                      jobType:"TRANSFER_LOCAL_FILES",
                      details:{
                        transferLocalFilesDetails:{
                          clientType:"TransferLocalFilesJobZero.client.types.1",
                          senders:data.fileTransfer.senders,
                          estimatedTimeInMs,
                          files:[
                            {
                              ...deepCopyInclude(
                                ["name","type","size"],fileTransfer
                              ),
                              streamSaver:{
                                fileStream,writer
                              },
                            }
                          ]
                        }
                      }
                    })
                    this.jobsService.jobPanelItem.custom.props.addJobSubj.next(targetJob)
                    // server does not run this job if the files make it success is captured we dont capture errors
                    if(!registeredJob){
                      registeredJob = true
                      let targetJobForServer = new ListJobsEntity({
                        ...targetJob
                      })
                      targetJobForServer.details.transferLocalFilesDetails.estimatedTimeInMs = estimatedTimeInMs + Date.now();
                      targetJobForServer.details.transferLocalFilesDetails.files = targetJobForServer.details.transferLocalFilesDetails.files.map((item)=>{
                        delete item.content
                        delete item.streamSaver
                        return item
                      })

                      this.jobsService.updateJobs(new UpdateJobsUIRequestBody({
                        jobs:[targetJobForServer],
                      }),true,false)
                      .pipe(
                        tap(()=>{
                          sendPacket({offset:0,data,status:"ASK_FOR_NEXT_CHUNK"})
                        })
                      )
                      .subscribe()
                    }
                    this.jobsService.jobPanelItem.open();
                  }
                  else{

                    let targetFile = getTargetFile(targetJob, fileTransfer)
                    if(!targetFile){

                      // @ts-ignore
                      targetJob.details["transfer_local_files_details"].files.push({
                        ...deepCopyInclude(["name","type","size"],fileTransfer),
                        // the key must be like this because the key casing gets transformed
                        stream_saver:{
                          file_stream:fileStream,writer
                        }
                      })
                      this.jobsService.listJobsDataSource.currentSource.data[targetJobIndex]= targetJob
                    }
                    sendPacket({offset:0,data,status:"ASK_FOR_NEXT_CHUNK"})
                  }
                }

                else if(["SEND_NEXT_CHUNK","FILE_SENT"].includes(fileTransfer.status)){


                  targetJob = transformObjectKeys(targetJob,transformFromSnakeCaseToCamelCase)
                  targetFile = getTargetFile(targetJob, fileTransfer,"camel")
                  let uint8Chunk = base64ToUint8Array(fileTransfer.chunk.chunk)

                  targetFile.content = [{
                    progress:fileTransfer.chunk.progress,
                    chunkSize: Math.ceil(conn.messageSize * CHUNK_SIZE_RATIO),
                    recipientDelayTime:RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS
                  }]
                  await targetFile.streamSaver.writer.write(uint8Chunk)

                  if(fileTransfer.status === "SEND_NEXT_CHUNK"){
                    sendPacket({offset:fileTransfer.chunk.offset,data,status:"ASK_FOR_NEXT_CHUNK"})
                  }
                  if(fileTransfer.status === "FILE_SENT"){

                    targetFile.streamSaver.writer.close()
                    sendPacket({status:"FILE_PROCESSED"})
                  }
                  this.updateJob({preventScrollReset:true,jobId:targetJob.id, status:targetJob.status, forceDataSourceUpdate:true, details:transformObjectKeys(targetJob.details,transformFromCamelCaseToSnakeCase)})

                }

                else if(["ALL_FILES_SENT"].includes(fileTransfer.status)){
                  targetJob = transformObjectKeys(targetJob,transformFromSnakeCaseToCamelCase)
                  let result =this.determineJobResults(targetJob);
                  sendPacket({status:"JOB_COMPLETE"})
                  if(!completedJob){

                    completedJob = true
                    let targetJobForServer = new ListJobsEntity({
                      ...targetJob,
                      status:result.status
                    })
                    targetJobForServer.details.transferLocalFilesDetails.files = targetJobForServer.details.transferLocalFilesDetails.files.map((item)=>{
                      delete item.content
                      delete item.streamSaver
                      return item
                    })

                    let serverId = this.jobsService.listJobsDataSource.currentSource.data.find((job) => {
                      return job.id === targetJob.id;
                    }).server_id;
                    targetJobForServer.id = serverId;
                    this.jobsService.updateJobs(new UpdateJobsUIRequestBody({
                      jobs: [targetJobForServer],
                    }), true).subscribe();
                  }
                  wakeLock?.release().then(()=>{})
                }
              } catch (error) {
                debugger
                captureMessage(error);

                sendPacket({status:"FILE_ERROR"})
                if(targetFile){
                  targetFile.status = "FAILED"
                  this.jobsService.listJobsDataSource.currentSource.data[targetJobIndex]= transformObjectKeys(targetJob,transformFromCamelCaseToSnakeCase)
                }
              }

            }
          });


        });
      })
    )


  }

  approveFileTransferRequest = (uiBody)=>{
    if(!this.peer?.id){
      this.reInit()
    }
    if(!this.listenForReceiveEventSub){
      this.listenForReceiveEventSub = this.listenForReceiveEvent().subscribe()
    }
    uiBody.senderPeerId = this.peer.id
    this.baseService.closePopup()
    return this.jobsService.respondToFileTransferRequest(uiBody)
  }

  listenForIncomingFileTransferRequest = ()=>{
    return this.socketioService.respondToFileTransferRequestEvent
    .pipe(
      // @ts-ignore
      map(requestFileTransferSuccess),
      tap((res)=>{
        // if(!this.accountsService.currentUser.incomingVideosNotificationsIsPresent){
        //   return
        // }
        let isSelf = makeLowerCase(res.data.result["senderEmail"]).trim() == makeLowerCase(this.accountsService.currentUser.businessEmail).trim()
        if(!isSelf){
          let approvedFriend = this.accountsService.currentUser.friends.find((friend)=>{
            return makeLowerCase(friend.email).trim() === makeLowerCase(res.data.result["senderEmail"]).trim() && friend.receiveFiles
          })
          if(!approvedFriend){
            return
          }
        }

        this.baseService.createWMLNote("TransferLocalFilesJobZero.WMLNotifyOne.keepSessionOpen",WMLNotifyOneBarType.Info)
        this.baseService.openPopup(new WMLCustomComponent({
          cpnt:ConfirmDialogZeroComponent,
          props:new ConfirmDialogZeroProps({
            propYesAction:()=>{

              if(isMobileIOSDevice()){
                // TODO FIGURE OUT HOW TO GET IOS TO LOGIN, and have the mobile client send the this.approveFileTransferRequest
                // this.accountsService.getCustomTokens()
                // .pipe(
                //   tap((res2)=>{
                //     this.utilService.getWindow().location.href =`eneobia://upload?senderEmail=${res.data.result.senderEmail}&senderSid=${res.data.result.senderSid}&jobId=${res.data.result.jobId}&loginToken=${res2.tokens[0]}`
                //   })
                // )
                // .subscribe()
                this.baseService.closePopup()
                this.baseService.openPopup(new WMLCustomComponent({
                  cpnt:ConfirmDialogZeroComponent,
                  props:new ConfirmDialogZeroProps({
                    propYesAction:()=>{
                      this.utilService.getWindow().location.href =`eneobia://upload?senderEmail=${res.data.result.senderEmail}&senderSid=${res.data.result.senderSid}&jobId=${res.data.result.jobId}`
                    },
                    propTitle:"webrtcService.localFileTransfer.confirmDialog"
                  })
                }))
              }
              else{
                this.approveFileTransferRequest(
                  new RespondToFileTransferRequestUIRequestBody({

                    ...deepCopyInclude(["senderEmail","senderSid","jobId"],res.data.result)
                  })
                ).subscribe()
              }

            },
            propTitle:"UploadZeroPage.localFileTransfer.confirmDialog",
            subtitle:new WMLUIProperty({
              text:res.data.result.senderEmail,
              style:{
                fontSize:CSSVARS.display
              }
            }),
          })
        }))
      })
    )

  }

  listenForFileTransferResponse = ()=>{
    return this.socketioService.fileTransferResponseEvent
    .pipe(
      // @ts-ignore
      map(respondToFileTransferRequestSuccess),
      concatMap((res)=>{
        return this.specificService.getWakeLock()
        .pipe(
          map((wakeLock)=>{
            return {
              res,wakeLock
            }
          })
        )
      }),
      tap(({res,wakeLock})=>{
        if(!this.peer?.id){
          this.reInit()
        }
        if(!this.listenForReceiveEventSub){
          this.listenForReceiveEventSub = this.listenForReceiveEvent().subscribe()
        }

        let targetJob = this.jobsService.listJobsDataSource.currentSource.data
        .find((job)=>{
          return job.id === res.data.result.jobId
        })

        this.updateJob({status:"IN_PROGRESS",jobId:targetJob.id})

        let conn = this.connect(res.data.result.senderPeerId)
        let filesToSend = targetJob.details.transfer_local_files_details.files
        let totalSize =filesToSend.reduce((acc,fileInfo)=>{
          return acc+ fileInfo.file.size
        },0)
        let filesCounter = 0
        let sendPacket =(input:Partial<{counter,status}>)=>{
          let {counter,status} = input
          let fileTransferChunk:any ={}
          if(["SEND_NEXT_CHUNK","FILE_SENT","FILE_START"].includes(status)){
            fileTransferChunk =  deepCopyInclude(
              ["name","type"],filesToSend[counter].file
            )

            if(["FILE_START"].includes(status)){
              fileTransferChunk.totalJobSize = totalSize
              fileTransferChunk.size =filesToSend[counter].file.size
              fileTransferChunk.senders = [res.data.result.senderEmail]
            }
          }
          conn.send(new WebRTCDataPacket({
            type:"FILE_TRANSFER",
            fileTransfer:{
              ...fileTransferChunk,
              jobId: targetJob.id,
              status,
            }
          }))
        }

        conn.on("error",(err)=>{
          this.baseService.createWMLNote("TransferLocalFilesJobZero.WMLNotifyOne.errorOnSendingFiles",WMLNotifyOneBarType.Error)
          this.updateJob({status:"FAILED",jobId:targetJob.id})
          wakeLock?.release().then(()=>{})
        })

        conn.on("open", () => {
          this.baseService.createWMLNote("TransferLocalFilesJobZero.WMLNotifyOne.keepSessionOpen",WMLNotifyOneBarType.Info)

          conn.on("data",(data:WebRTCDataPacket)=>{
            console.log(data.fileTransfer)
            if(data.type ==="FILE_TRANSFER"){
              let {fileTransfer} = data
              if(["FILE_PROCESSED","FILE_ERROR"].includes(fileTransfer.status )){
                if(fileTransfer.status === "FILE_ERROR"){

                  filesToSend[filesCounter].status = "FAILED"
                }
                filesCounter+=1

                if(filesCounter < filesToSend.length){
                  sendPacket({counter:filesCounter,status:"FILE_START"})
                }
                else{
                  // TODO should result be handled in the ack? or here
                  sendPacket({status:"ALL_FILES_SENT"})
                }
              }
              else if( ["ASK_FOR_NEXT_CHUNK"].includes(fileTransfer.status)){
                let targetFile = targetJob.details.transfer_local_files_details.files.find((fileInfo)=>{
                  return fileInfo.file.name === fileTransfer.name
                })

                let messageSize = Math.ceil(conn.messageSize *CHUNK_SIZE_RATIO)
                let offset = fileTransfer.chunk.offset
                readFileContent(
                  targetFile.file,'readAsArrayBuffer',false,"seek",0,
                  {
                    offset,
                    length:messageSize
                  }
                )
                .pipe(
                  tap((res)=>{
                    let chunk = arrayBufferToBase64(res.content)
                    // console.log(res)
                    // TODO mabye calculate the size of the chunk and use that to otpmize the message size of the file chunk
                    let msgToSend = {
                      type:"FILE_TRANSFER",
                      fileTransfer:{
                        ...data.fileTransfer,
                        chunk:{
                          chunk,
                          offset:offset+messageSize,
                          progress:res.progress
                        },
                        status:res.progress < 100 ? "SEND_NEXT_CHUNK":"FILE_SENT"
                      }
                    }
                    // console.log(conn.messageSize)
                    // console.log(JSON.stringify(msgToSend).length)
                    // @ts-ignore
                    conn.send(new WebRTCDataPacket(msgToSend))
                    targetFile.content = [{
                      progress:res.progress,
                      chunk_size:messageSize,
                      recipient_delay_time:fileTransfer.recipientDelayTime
                    }]
                    if(fileTransfer.estimatedTimeInMs){
                      targetJob.details.transfer_local_files_details.estimated_time_in_ms = fileTransfer.estimatedTimeInMs
                    }
                    targetFile.size = filesToSend[filesCounter].file.size
                    this.updateJob({status:targetJob.status, forceDataSourceUpdate:true,preventScrollReset:true,jobId:targetJob.id, details:targetJob.details})
                  })
                )
                .subscribe()
              }
              else if(["JOB_COMPLETE"].includes(data.fileTransfer.status)){
                let result = this.determineJobResults(transformObjectKeys(targetJob,transformFromSnakeCaseToCamelCase));

                this.storeService.useCredits(new UseCreditsUIRequestBody({
                  creditType:"file_transfer",
                  amount:result.successAmount
                }))
                .pipe(
                  tap(()=>{
                    this.accountsService.currentUser.credits.fileTransfer -=result.successAmount
                    wakeLock?.release().then(()=>{})
                  }),
                  catchError(()=>{
                    return EMPTY
                  })
                )
                .subscribe()
              }

            }
          })

          sendPacket({counter:filesCounter,status:"FILE_START"})

        });
      }),

    )

  }

  listenForFileTransferError = ()=>{
    return this.socketioService.fileTransferErrorEvent
    .pipe(
      tap((res)=>{
        if(res.data.result === NEED_MORE_CREDITS){
          this.specificService.purchaseMoreCredits()
          this.baseService.closePopup()
        }
        else if(
          [HttpUtils.isServerError(res.code),HttpUtils.isClientError(res.code)].includes(true)
        ){
          this.baseService.openSystemError()
        }
      })
    )

  }

}

@WMLConstructorDecorator
export class WebRTCDataPacket {
  constructor(props: Partial<WebRTCDataPacket> = {}) { }

  type:"FILE_TRANSFER"
  fileTransfer:WMLDeepPartial<{
    senders:Array<string>
    name:string,
    type:string,
    size:number
    totalJobSize:number
    chunk:{
      chunk:any,
      offset:number,
      progress:number
    },
    estimatedTimeInMs:number
    // TODO change to MS
    recipientDelayTime:number
    jobId:string,
    status:"FILE_START" | "SEND_NEXT_CHUNK" | "FILE_SENT" | "ASK_FOR_NEXT_CHUNK" |"FILE_PROCESSED" |"ALL_FILES_SENT" | "FILE_ERROR" |"JOB_COMPLETE"
    // TODO mabye file error
  }>
}
